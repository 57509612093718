import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {getReport, getUser}       from '../../actions/Fetch';
import {getPropertyFromLocation}  from '../../actions/GetProperty';

import Spinner          from '../../atoms/Spinner';
import InfoUser         from '../../molecules/InfoUser';
import InfoRepo         from '../../molecules/InfoRepo';
import ImageCheki       from '../../molecules/ImageCheki';
import DownloadReminder from '../../organisms/DownloadReminder';
import Conversation     from '../../organisms/Conversation';
import NotFoundPage     from '../../pages/NotFoundPage';

class ReportPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      index:0,                //アクティブなタブを管理
      isLoading:true,         //Loading画面の表示を管理
      pageNotFound: false,    //NotFount画面の表示を管理
      userId:"",              //ユーザーのidを管理
      report:[],              //レポートの情報を管理
      userInfo:[],            //ユーザー情報を管理
      fromReportList: getPropertyFromLocation(props.location, "fromReportList"),//レポート一覧からやってきたどうかを管理
    };

    /*子コンポーネントに渡す関数をbind*/
      this.setState = this.setState.bind(this);
  }


  componentDidMount(){
    /*ブラウザバックでやってきたなら以前の情報を表示*/
      if(this.state.fromReportList){  this.setState( this.props.history.location.state,  );  }
    /*ブラウザバック以外でやってきたならfetch*/
      else{
        const reportId = this.props.match.params.reportId ;
        getReport(reportId, this.setState)
        .then(()=>{return getUser(this.state.userId, this.setState);})
        .then(()=>{return this.setState({isLoading:false});})
      }
  }


/*スワイプ出来るやつのタブ切り替えを行う関数*/
  handleIndex = index => {                      //タブ(Nav)のeventKey(str)を受け取って
    this.setState({  index:Number(index),  });  //intに直してindex(state)に入れている
  };


  render() {
    const index = this.state.index;
    const report = this.state.report;
    const userInfo = this.state.userInfo;

    //fetchする前 loading表示
    if(this.state.isLoading){
      return( <Spinner className="pt-5 text-center text-primary"/> );
    }

    //fetch失敗 not found page表示
    else if(this.state.pageNotFound){
      return( <NotFoundPage  text="レポート"/> );
    }

    //fetch成功 ページ表示
    else{
      return (
        <Container  className="px-2 py-3"  style={{maxWidth:"960px"}}  >
          <div className="mt-4 d-none d-sm-block"/>
          <div className="p-2 border bg-white">

          {/*レポートページから来た時に戻るボタン追加*/}
            {this.state.fromReportList &&
              <div className="d-none d-sm-block">

                <div onClick={() => this.props.history.goBack()} className="fa1-5 text-primary px-2 link-style" style={{cursor:"pointer"}}>
                  <FontAwesomeIcon icon={['fas', 'arrow-left']}/>
                </div>
              <hr className="mt-1 mb-3"/>
              </div>
            }





          {/*レポート本体*/}
            <Row className="justify-content-center mt-2" >

            {/*左側*/}
              <Col xs={12} sm={6} className="">

              <div className="border mb-3">

                {/*スワイプできるやつのタブ*/}
                  <Nav fill activeKey={`${index}`} onSelect={this.handleIndex} className="nav-tab">
                    <Nav.Item>  <Nav.Link eventKey="0">Cheki</Nav.Link> </Nav.Item>
                    <Nav.Item>  <Nav.Link eventKey="1">Talk</Nav.Link>  </Nav.Item>
                  </Nav>

                {/*スワイプできるやつ*/}
                  <SwipeableViews  animateHeight  index={index}  onChangeIndex={this.handleIndex}  className=""  >
                    <div style={Object.assign({},{height:"400px",width:"100%",})}>
                      <ImageCheki  className="mx-auto" cheki={report.ChekiUrl} />
                    </div>
                    <div style={Object.assign({},{height:"400px", width:"100%", overflowY: 'auto',backgroundColor: "#87ceeb"})}>
                      <Conversation  conversations={report.Conversations}  otaku={userInfo.IconUrl}  idol={report.IdolIconUrl} />
                    </div>
                  </SwipeableViews>



                </div>
              </Col>


            {/*右側*/}
              <Col xs={12} sm={6} className="">

                <hr/>
                  {/*ユーザー情報*/}
                    <InfoUser
                      report
                      className="p-1"
                      icon={userInfo.IconUrl}
                      name={userInfo.Name}
                      id={userInfo.Id}
                      url={this.props.match.url}
                    />



                <hr className="mb-2"/>

                  {/*ダウンロード催促*/}
                    <DownloadReminder className="py-3"/>

                <hr className="my-2"/>

                  {/*レポート情報*/}
                    <InfoRepo
                      className="px-1 py-2"
                      Idol = {report.Idol}
                      IdolIconUrl = {report.IdolIconUrl}
                      IdolGrpup = {report.IdolGroup}
                      EventName={report.EventName}
                      EventDate={report.EventDate}
                      PostDate={report.PostDate}
                      Caption={report.Caption}
                    />

                <hr className="my-2"/>

              </Col>

            </Row>

          </div>
        </Container>
      );
    }
  }


}


export default withRouter(ReportPage);
