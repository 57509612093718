import React from 'react';
import { Button, Modal } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';

  const SignOutButtonBase = ({firebase}) => (
    <Button  onClick={firebase.doSignOut} variant="outline-primary">
      ログアウト
    </Button>
  );

  function SignOutModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
          <h5>ログアウトしますか？</h5>
      </Modal.Header>

      <Modal.Footer>
        <SignOutButton />
        <Button onClick={props.onHide}>キャンセル</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ButtonSignOutModal(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button className="px-0" variant="link" onClick={() => setModalShow(true)}>
        ログアウト
      </Button>

      <SignOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}


const SignOutButton = compose(
  withRouter,
  withFirebase,
)(SignOutButtonBase);


export default ButtonSignOutModal;
export { SignOutButton };
