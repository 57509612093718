import React from 'react';

import './index.css';


function Balloon(props) {
  return (
    <div className={props.className}>
      <p>{props.content}</p>
    </div>
  );
}


export default Balloon;
