import React from 'react';
import { Media } from 'react-bootstrap'

import Balloon from '../../atoms/Balloon';


function TalkStaff(props) {
  return (
    <Media className={`justify-content-center ${props.className}`}>
      <Balloon className="balloonNone" content={props.content}/>
    </Media>
  );
}


export default TalkStaff;
