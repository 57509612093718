import React from 'react';
import { Media } from 'react-bootstrap'

import Balloon from '../../atoms/Balloon';
import IconS from '../../atoms/IconS';

function TalkUser(props) {
  return (
    <Media className={`justify-content-end ${props.className}`}>
      <Balloon className="balloonRight" content={props.content}/>
      <IconS className="mr-1" icon={props.icon}/>
    </Media>
  );
}

export default TalkUser;
