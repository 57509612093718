import React,{Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withFirebase } from '../../Firebase';

import {userSignUp} from '../../actions/Fetch';



function SignInModal(props) {
return (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
        <h5>ログインしますか？</h5>
    </Modal.Header>
    <Modal.Body style={{margin:"25px 0"}}>
        <SignInTwitter/>
    </Modal.Body>
    <Modal.Footer>
      <p className="small text-gray">
        初回ログインの際、自動的にユーザー登録が行われます。おたぐらむを利用することで、下記のプライバシーポリシー及び利用規約に同意することとします。
      </p>
      <div className="">
        <Link to={`/privacy-policy`} className="pr-3">プライバシーポリシー</Link>
        <Link to={`/tos`}>利用規約</Link>
      </div>
    </Modal.Footer>
  </Modal>
);
}

function ButtonSignInModal(props) {
const [modalShow, setModalShow] = React.useState(false);

return (
  <>
    <Button onClick={() => setModalShow(true)} variant={props.variant}>
      {props.title}
    </Button>

    <SignInModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    />
  </>
);
}







class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error:null,
      user:"",
    };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser =>{
        console.log(socialAuthUser);
        if(socialAuthUser.additionalUserInfo.isNewUser){

            return userSignUp(
              socialAuthUser.user.uid,
              socialAuthUser.user.displayName,
              socialAuthUser.user.photoURL,
              socialAuthUser.user.xa
            )
            .then((data)=>{
              this.setState({ error:null, user:data.result.Result.Id });
            });

        }else{
          this.setState({ error:null, user:socialAuthUser.user.uid });
        }

      })
      .then(() =>{
        window.location.href = "/user/"+this.state.user;
      })
      .catch(error => {
        this.setState({error});
      });

    event.preventDefault();
  };

  render(){
    const { error } = this.state;

    return(
      <>
        <Button onClick={this.onSubmit} className="btn-twitter">
          <FontAwesomeIcon icon={['fab', 'twitter']} />{' '}
          Twitterでログイン
        </Button>

        { error && <p>{error.message}</p>}
      </>
    );
  }
}

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default ButtonSignInModal;
export { SignInTwitter };
