import React from 'react';

function Spinner(props){
  return(
    <div className={`${props.className}`}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Spinner;
