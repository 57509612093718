import React from 'react';
import { Container } from 'react-bootstrap';

import DownloadReminder from '../../organisms/DownloadReminder';

function NotFoundPage(props){
  return(
    <Container
      className="mt-3"
      style={{maxWidth:"940px"}}
    >
      <div className="text-center py-5">
          <h5>{props.text}が見つかりませんでした</h5>
          <img className="px-auto py-3" style={{maxWidth:"250px"}} alt="not found"
            src="../not_found_dog.png"
          />
      </div>
      <DownloadReminder className="py-3"/>
    </Container>
  );
}

export default NotFoundPage;
