import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { Row, Col,Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

import Date from '../../atoms/Date';
import IconS from '../../atoms/IconS';
import ImageThumbnail from '../../molecules/ImageThumbnail';


class ListAbstract extends PureComponent{
  componentDidUpdate() {
      this.context.swipeableViews.slideUpdateHeight();
    }

  render(){
  const data = this.props.data;

  const reportList = data.map((report) =>{

    return(
      <div key={report.Id}>
      <Row
        as={Link}
        to={{
          pathname: `/report/${report.Id}`,
          state: {
            index:1,
            fromReportList: true,
            isLoading:false,
            pageNotFound:false,
            report:report,
            conversations:report.Conversations,
            userInfo:this.props.user,
          }
        }}
        className="justify-content-center link-style text-black mx-0"
      >
        <Col xs={6} sm={6}>
          <h5 className="mb-0"> <Date time={report.EventDate}/> </h5>

            <Media className="my-1" style={{width:"100%"}}>
            <LazyLoad height={"100%"}>
              <IconS icon={report.IdolIconUrl} className="mr-1" style={{width:"44px"}}/>
              <span className="align-self-center res-text-name" style={{width: `calc(100% - 44px)`}}>{report.Idol}</span>
            </LazyLoad>
            </Media>

            {report.IdolGroup &&
              <div>
                <p className="mb-1"> {report.IdolGroup} </p>
              </div>
            }

            <p className="small">posted  <Date  time={report.PostDate}/> </p>
        </Col>

        <Col xs={6} sm={4}>
          <ImageThumbnail
            cheki={report.ChekiUrl}
          />
        </Col>
      </Row>
      <hr className="my-2"/>
      </div>
    )}
  );

  return(
    <div className="my-2">
      {reportList}
      {data.length === 0 &&
        <div className="py-4">
          <h5 className="text-center text-muted"> まだレポートがありません</h5>
        </div>
      }
    </div>
  );
  }
}

ListAbstract.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};

export default ListAbstract;
