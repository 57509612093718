import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withAuthentication } from '../Session';

import PageTopNavbar from '../organisms/PageTopNavbar';

import TopPage from '../pages/TopPage';
import UserPage from '../pages/UserPage';
import ReportPage from '../pages/ReportPage';
import DocumentPage from '../pages/DocumentPage';
import ScrollToTop from '../actions/ScrollToTop';

import {PrivacyPolicy, TurmsOfService, Help} from '../documents';


import * as ROUTES from '../../constants/routes';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faShareSquare, faEdit } from '@fortawesome/free-regular-svg-icons'
import { faHourglassHalf, faListUl, faTh, faArrowLeft, faHome, faCog } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faShareSquare, faEdit, faHourglassHalf, faListUl, faTh, faArrowLeft, faHome, faCog)



const App = () => (
  <Router>
    <ScrollToTop>
      <div>
        <PageTopNavbar />
        <Switch>
          <Route exact path={ROUTES.TOP} render={() => <TopPage/>}/>
          <Route exact path={ROUTES.USER} render={() => <UserPage/>}/>
          <Route exact path={ROUTES.REPORT} render={() =><ReportPage/>}/>
          <Route exact path={ROUTES.PRIVACY_POLICY} render={() =><DocumentPage title="プライバシーポリシー" contents={<PrivacyPolicy/>}   />} />
          <Route exact path={ROUTES.TOS}            render={() =><DocumentPage title="利用規約"            contents={<TurmsOfService/>}  />} />
          <Route exact path={ROUTES.HELP}           render={() =><DocumentPage title="ヘルプ"              contents={<Help/>}            />} />
          <Route path="*" render={() => <Redirect to={ROUTES.TOP}/>}/>
        </Switch>
      </div>
    </ScrollToTop>
  </Router>
);


export default withAuthentication(App);
