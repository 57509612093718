import React from 'react';
import { Form , Button, Accordion, Card } from 'react-bootstrap';


function SearchUser (props) {
  return (
    <Accordion>
      <Card>

        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          ユーザー内検索
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Form onSubmit={props.handleSortFilter} className="px-2">
              <Form.Row>
                <Form.Control
                  style={{width:'calc(100% - 135px)'}}
                  className="mr-1"
                  type="text"
                  placeholder="アイドル名"
                  value={props.idol}
                  onChange={(event)=>{props.setParentState({idol:event.target.value})}}
                />
                <Form.Control
                  as="select" style={{width:"130px"}}
                  value={props.order}
                  onChange={(event)=>{props.setParentState({order:event.target.value})}}
                >
                  <option value="PostDate">投稿順</option>
                  <option value="EventDate">イベント順</option>
                </Form.Control>
                <Button style={{width:'100%', marginTop:"5px"}} variant="outline-info" type="submit">実行</Button>
              </Form.Row>
            </Form>
          </Card.Body>
        </Accordion.Collapse>

      </Card>
    </Accordion>
  );
}


export default SearchUser;
