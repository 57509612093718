const url = `https://otagram.appspot.com/jrpc`;
const authorizationToken = "9b1f57850308b9f061804445d33972fa6ce022ee0842604df2b6cb8169c77a7e2be24801920145328f099c9383a136c79b92ebd17340318d8a7489a220c20f3d"

export function getReportByUserId(userId, setParentState) {
      return fetch(url, {
        "method": "POST",
        "headers": {
          "Content-Type": "application/json",
          "Authorization": authorizationToken,
        },
        "body":  JSON.stringify({
          "jsonrpc": "2.0",
          "method": "Otagram.GetReportByUserId",
          "params":{
            "user_id": userId,
          },
          "id": 1,
        }),
      })
      .then(response => {return response.json()})
      .then( data =>{
          let userReportList = data.result.Result.slice();    //response内のリポートリストをコピー
          userReportList.sort(function(a,b){                  //コピーしたリストを投稿時刻順に並び替え
            if(a.PostDate>b.PostDate) return -1;
            if(a.PostDate < b.PostDate) return 1;
            if(a.EventDate>b.EventDate) return -1;
            if(a.EventDate < b.EventDate) return 1;
            return 0;
          });
          setParentState({                                     //リストをStateに入れる
            userReportList:userReportList,
            renderReportList:userReportList,
          });})
      .catch(error => {                                       //errorをcatch
        console.error(error)
        setParentState({ pageNotFound:true });
      });
  }

export function getUser(userId, setParentState) {
      return fetch(url, {
        "method": "POST",
        "headers": {
          "Content-Type": "application/json",
          "Authorization": authorizationToken,
        },
        "body":  JSON.stringify({
          "jsonrpc": "2.0",
          "method": "Otagram.GetUser",
          "params":{
            "user_id": userId,
          },
          "id": 1,
        }),
      })
      .then(response => {return response.json()})
      .then(data =>{
        setParentState({ userInfo:data.result.Result, });  })  //responseのユーザー情報をStateに入れる
      .catch(error => {                                       //errorをcatch
        console.error(error)
        setParentState({ pageNotFound:true });
      });
  }


export function getReport(reportId, setParentState) {
      return fetch(url, {
        "method": "POST",
        "headers": {
          "Content-Type": "application/json",
          "Authorization": authorizationToken,
        },
        "body":  JSON.stringify({
          "jsonrpc": "2.0",
          "method":"Otagram.GetReport",
          "params":{
            "report_id": reportId,
          },
          "id": 1,
        }),
      })
      .then(response => {return response.json()})
      .then(data =>{
        setParentState({
          report:data.result.Result,
          userId:data.result.Result.User,
        });
      })
      .catch(error => {
        console.error(error)
        setParentState({ pageNotFound:true });
      });
  }


  export function userSignUp(userId, userName, userIconUrl, token) {
      console.log("fetch userSignUp");
        return fetch(url, {
          "method": "POST",
          "headers": {
            "Content-Type": "application/json",
            "Authorization": token,
          },
          "body":  JSON.stringify({
            "jsonrpc": "2.0",
            "method": "Otagram.UserSignUp",
            "params":{
              "user_id": userId,
              "user_name": userName,
              "user_icon_url": userIconUrl,
            },
            "id": 1,
          }),
        })
        .then(response => {return response.json()})
        .catch(error => { console.log(error) })
    }



    export function getIdToken(refreshToken) {
          return fetch(``, {
            "method": "POST",
            "headers": {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            "body": `grant_type=refresh_token&refresh_token=${refreshToken}`,
          })
          .then(response => {
            return response.json();
          })
          .catch(error => { console.log(error) })
      }
