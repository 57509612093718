import React from 'react';
import { Media } from 'react-bootstrap';


import NewLine from '../../atoms/NewLine';
import Date from '../../atoms/Date';
import IconS from '../../atoms/IconS';

function InfoRepo(props) {

  return (
      <div className={`${props.className}`}>
        <p className="mb-0"> <Date time={props.EventDate}/> </p>

        <Media className="my-1">
          <IconS icon={props.IdolIconUrl} className="mr-2"/>
          <span className="align-self-center">{props.Idol}</span>
        </Media>

        {props.IdolGroup &&
          <div>
        <p className="small mb-0"> group</p>
        <p className="mb-1"> {props.IdolGroup} </p>
          </div>
        }

        {props.EventName &&
          <div>
        <p className="small mb-0"> event</p>
        <p className="mb-1"> {props.EventName} </p>
          </div>
        }

        {props.Plase &&
          <div>
            <p className="small mb-0"> place</p>
            <p className="mb-1">{props.Place} </p>
          </div>
        }

        {props.Caption &&
          <div>
        <p className="small mb-0"> caption</p>
        <p className="mb-1">  <NewLine text={props.Caption}/> </p>
          </div>
        }

        <p className="small mb-0"> posted</p>
        <p className="small">  <Date withTime time={props.PostDate}/> </p>
      </div>
  );
}


export default InfoRepo;
