import React from 'react';


function IconS(props) {
  if(props.icon === ""||props.icon === "empty" || props.icon === undefined){
    return (
      <img
        width={40}
        height={40}
        className={`${props.className} rounded-circle`}
        src="../person.png"
        alt="Icon"
        style={{backgroundColor:"white"}}
      />
    );
  }else{
    return (
      <img
        width={40}
        height={40}
        className={`${props.className} rounded-circle`}
        src={props.icon}
        alt="Icon"
      />
    );
  }

}


export default IconS;
