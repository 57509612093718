import React from 'react';
import { Container, Row, Col, Card, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ButtonSignInModal from '../../molecules/ButtonSignInModal';
import DownloadReminder from '../../organisms/DownloadReminder';

import * as ROUTES from '../../../constants/routes';

function TopPage(props) {
  return (
    <div className="top" style={{}}>
      <div
        style={{
          width:"100%",backgroundColor:"",minHeight:`calc(100vh - 97px)`,
          display:"flex", justifyContent: "center", alignItems: "center"
        }}
      >
        <Container className="text-center" style={{maxWidth:"940px"}}>

          <Row className="py-5" style={{justifyContent: "center", alignItems: "center"}}>

            <Col md={5} className=" d-none d-md-block">
              <img width="80%" src="phone.png" alt="phone" style={{pointerEvents: "none"}}/>
            </Col>

            <Col md={5} className="">
              <Card className="py-5">
                <div className="py-4">
                  <h1 className="display-5 pb-2">おたぐらむ</h1>
                  <h4 className="h5">推しメンとの思い出をのこそう</h4>
                </div>
                <hr className="mx-3 my-4"/>
                <DownloadReminder className="pt-3"/>

              </Card>
              {/*
              <div className="pt-4">
                <p className="mb-2">アプリをお持ちの方は</p>
                <ButtonSignInModal title="ログイン" variant="outline-primary"/>
              </div>
              */}
            </Col>





          </Row>



          <div className="pt-5 pb-2 text-center">
            <Link to={ROUTES.HELP} className="pr-3">ヘルプ</Link>
            <Link to={ROUTES.TOS} className="pr-3">利用規約</Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="pr-3">プライバシー</Link>
          </div>


        </Container>
      </div>


      <Navbar bg="dark" className="py-2">
        <p className="my-0 mx-auto text-white">© おたぐらむ</p>
      </Navbar>

    </div>
  );
}


export default TopPage;
