import React from 'react';
import { Container, Card, Navbar } from 'react-bootstrap';


function DocumentPage(props) {
  return (
    <div>
        <Container className="py-5" style={{maxWidth:"940px"}}>
          <h1 className="display-4 py-5">{props.title}</h1>

          <Card body className="my-4 text-dark list-indent">
            {props.contents}
          </Card>

        </Container>


      <Navbar bg="dark" className="py-2">
        <p className="my-0 mx-auto text-white">© おたぐらむ</p>
      </Navbar>

    </div>
  );
}


export default DocumentPage;
