import React from 'react';
import { Navbar } from 'react-bootstrap'
import  { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AuthUserContext } from '../../Session';

import ButtonSignInModal from '../../molecules/ButtonSignInModal';

import * as ROUTES from '../../../constants/routes';


function PageTopNavbar(props){
  return(
    <div>
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? <NavbarAuth userId={authUser.uid}/>
                   : <NavbarNonAuth />
        }
      </AuthUserContext.Consumer>
    </div>
  )
}

function NavbarAuth  (props) {
  return(
  <Navbar bg="white" className="border-bottom">
    <Navbar.Brand  as={Link} to={ROUTES.TOP} >Otagram</Navbar.Brand>
    <Navbar.Collapse className="justify-content-end">
          <a
            style={{cursor: "pointer", color:"gray"}}
            href={`/user/${props.userId}`}
            className="link-style p-1 "
          >
            <FontAwesomeIcon icon={['fas', 'home']}/>MyPage
          </a>

    </Navbar.Collapse>
  </Navbar>
);
}

function NavbarNonAuth (props){
  return(
    <Navbar bg="white" className="border-bottom">
      <Navbar.Brand  as={Link} to={ROUTES.TOP} >Otagram</Navbar.Brand>
      {/*
      <Navbar.Collapse className="justify-content-end">
        <ButtonSignInModal title="ログイン/登録"/>
      </Navbar.Collapse>
      */}
    </Navbar>
  );
}



export default withRouter(PageTopNavbar);
