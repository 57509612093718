import React from 'react';
import { Media, ButtonGroup, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TwitterShareButton, TwitterIcon } from 'react-share';

import IconM from '../../atoms/IconM';
import ButtonSignOutModal from '../../molecules/ButtonSignOutModal';
import { AuthUserContext } from '../../Session';


import * as ROUTES from '../../../constants/routes';

/*
要求するprops
id              :Firebaseのuid
icon            :アイコンがそうURL
name            :ユーザー名
numberOfReports :レポート数(user:trueのときのみ)
report / user   :boolean
*/

function InfoUser(props) {
  //ReportPage用
    if(props.report){
      return(
        <Row  className={`mx-0 px-2 ${props.className}`}>
          <Col
            as={Link} to={`/user/${props.id}`}
            className="link-style text-black px-0"
            style={{width:`calc(100%-78px)`}}
          >
            <IconM icon={props.icon} className="align-self-center mr-3"/>
            <span className="align-self-center h5">{props.name}</span>
          </Col>

          {/*Shereボタン*/}
            <Col
              className="text-right align-self-center px-0"
              style={{width:`calc(78px)`}}
            >
              <span className="mr-1">共有</span>
              <TwitterShareButton title=""
                                  hashtags={["おたぐらむ"]}
                                  url={`https://www.otagram.com${props.url}`}
              >
                <TwitterIcon size={40} round />
              </TwitterShareButton>
            </Col>
        </Row>
      );
    }

  //UserPage用
    if(props.user){
      return(
        <Media className="py-2">
          <IconM icon={props.icon} className="mr-3"/>

          <div className="align-self-center">
          <p className="h5 mb-0">{props.name}</p>
          <p className="pt-2 mb-0">全レポ数：{props.numberOfReports}件</p>
          </div>

          <AuthUserContext.Consumer>
            {authUser =>
              <>{ {...authUser}.uid === props.id &&
                <div className="align-self-center ml-auto">

                  <ButtonGroup>
                   <DropdownButton
                    title=""
                    as={ButtonGroup}
                    variant="outline-info" alignRight
                  >
                      <Dropdown.Item className="my-2">設定(準備中)</Dropdown.Item>
                      <Dropdown.Item className="my-2" as={Link} to={ROUTES.HELP}>ヘルプ</Dropdown.Item>
                      <hr className="mt-3 mb-2"/>
                      <Dropdown.Item><ButtonSignOutModal/></Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                </div>

              }</>

            }
          </AuthUserContext.Consumer>

        </Media>
      );
    }
}


export default InfoUser;
