import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views'
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import {getReportByUserId, getUser} from '../../actions/Fetch';
import {getPropertyFromLocation}    from '../../actions/GetProperty';

import Spinner          from '../../atoms/Spinner';
import InfoUser         from '../../molecules/InfoUser';
import ListThumbnail    from '../../organisms/ListThumbnail';
import ListAbstract     from '../../organisms/ListAbstract';
import SearchUser       from '../../organisms/SearchUser';
import NotFoundPage     from '../../pages/NotFoundPage';
import DownloadReminder from '../../organisms/DownloadReminder';

class UserPage extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      index:0,                //アクティブなタブを管理
      isLoading:true,         //Loading画面の表示を管理
      pageNotFound: false,    //NotFount画面の表示を管理
      userReportList:[],      //サーバーから受け取ったレポートリストを管理
      renderReportList:[],    //実際に描写するレポートリストを管理
      userInfo:[],            //ユーザー情報を管理
      idol:"",                //アイドル名での絞り込みの条件を管理
      order:"PostDate",        //並び替えの条件を管理
      browserBack: getPropertyFromLocation(props.location, "browserBack"),  //browserBackでやってきたどうかを管理
    };

    /*子コンポーネントに渡す関数をbind*/
      this.setState = this.setState.bind(this);
      this.handleSortFilter = this.handleSortFilter.bind(this);
  }


  componentDidMount(){
    /*ブラウザバックでやってきたなら以前の情報を表示*/
      if(this.state.browserBack){  this.setState(  this.props.history.location.state  );  }
    /*ブラウザバック以外でやってきたならfetch*/
      else{
        const userId = this.props.match.params.userId ;
        Promise.all([getReportByUserId(userId, this.setState),getUser(userId, this.setState)])
        .then(()=>{  this.setState({ isLoading:false, browserBack:true, });  })
      }
  }

  componentDidUpdate(prevProps){
    /*stateが書き換わるたびにlocationに挿入*/
      if (this.state !== this.props.location.state) {
        this.props.history.replace({//現在のstateをlocationのstateに詰めている
          pathname: this.props.history.location.pathname,
          state: this.state,
        });
      }
  }

/*スワイプ出来るやつのタブ切り替えを行う関数*/
  handleIndex = index => {                      //タブ(Nav)のeventKey(str)を受け取って
    this.setState({  index:Number(index),  });  //intに直してindex(state)に入れている
  };


/*ページ内検索を行う関数*/
  handleSortFilter = e => {
    var reports = this.state.userReportList.slice();  //userReportListをコピー
    if(this.state.order === "EventDate"){             //orderがEventDateならイベント順に並び替え
      reports = reports.sort(function(a,b){
        if(a.EventDate>b.EventDate) return -1;  if(a.EventDate < b.EventDate) return 1;   return 0;
      });
    }
    if(this.state.idol !== ""){                       //idolに文字列があればその文字列で絞り込み
      reports = reports.filter(report => report.Idol.indexOf(this.state.idol)>-1);
    }
    this.setState({  renderReportList:reports });     //得られたレポートリストをstateに詰める
    e.preventDefault();                               //submitによるページ遷移を阻止
  }




  render() {
    const  index  = this.state.index;
    const reportList = this.state.renderReportList;
    const userInfo = this.state.userInfo;

    //fetchする前 loading表示
    if(this.state.isLoading){
      return( <Spinner className="pt-5 text-center text-primary"/> );
    }

    //fetch失敗 not found page表示
    else if(this.state.pageNotFound){
      return( <NotFoundPage text="ユーザー"/> );
    }

    //fetch成功 ページ表示
    else{
      return (
        <Container className="my-3" style={{maxWidth:"800px"}} fluid>

            {/*ユーザー情報*/}
              <InfoUser
                user
                className="p-1"
                icon={userInfo.IconUrl}
                name={userInfo.Name}
                id={userInfo.Id}
                numberOfReports={this.state.userReportList.length}
              />

          <hr className="my-3"/>

            {/*ユーザーページ内の検索*/}
              <SearchUser
                setParentState={this.setState}
                handleSortFilter={this.handleSortFilter}
                idol={this.state.idol}
                order={this.state.order}
              />

          <hr className="my-3"/>

            {/*ダウンロード催促*/}
              <DownloadReminder className="py-4"/>

          <hr className="mt-3 mb-2"/>

            {/*スワイプできるやつのタブ*/}
              <Nav justify className="nav-tab" activeKey={`${index}`} onSelect={this.handleIndex}>
                <Nav.Item>  <Nav.Link eventKey="0"><FontAwesomeIcon icon={['fas', 'th']} size="2x"/></Nav.Link>  </Nav.Item>
                <Nav.Item>  <Nav.Link eventKey="1"><FontAwesomeIcon icon={['fas', 'list-ul']} size="2x"/></Nav.Link>  </Nav.Item>
              </Nav>

          <hr/>

            {/*スワイプできるやつ*/}
              <SwipeableViews animateHeight  index={index}  onChangeIndex={this.handleIndex}>
                <div style={Object.assign({})}>  <ListThumbnail data={reportList} user={userInfo}/>  </div>
                <div style={Object.assign({})}>  <ListAbstract  data={reportList} user={userInfo}/>  </div>
              </SwipeableViews>

        </Container>
      );
    }
  }
}




export default withRouter(UserPage);
