import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ImageThumbnail from '../../molecules/ImageThumbnail';


class ListThumbnail extends PureComponent{

  componentDidUpdate() {
      this.context.swipeableViews.slideUpdateHeight();
    }

  render(){
    const data = this.props.data;
    const reportList = data.map((report,index) =>{
      return(
        <Col key={report.Id} xs={6} sm={4} className="p-1 link-style"
          as={Link}
          to={{
            pathname: `/report/${report.Id}`,
            state: {
              index:1,
              fromReportList: true,
              isLoading:false,
              pageNotFound:false,
              report:report,
              conversations:report.Conversations,
              userInfo:this.props.user,
            }
          }}
        >

          <ImageThumbnail
            cheki={report.ChekiUrl}
            icon={report.IdolIconUrl}
          />

        </Col>
      )}
    );

    return(
      <div className="py-2 my-0">
        <Row className="mx-0">
          {reportList}
        </Row>
        {data.length === 0 &&
          <div className="py-4 text-center text-muted">
            <h5>まだレポートがありません</h5>
          </div>
        }
      </div>
    );
  }
}

ListThumbnail.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};


export default ListThumbnail;
