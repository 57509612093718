import React from 'react';

import TalkUser from '../../molecules/TalkUser';
import TalkIdol from '../../molecules/TalkIdol';
import TalkStaff from '../../molecules/TalkStaff';



function Conversation(props) {
  var conversations = props.conversations;

  if(conversations === null || conversations === []){
    return(
      <div className="text-center text-muted" style={{height:"400px", paddingTop:"180px"}}>
        <h4>Talkなし</h4>
    </div>);
  }else{

    return (
      <div
        className={`pb-4 ${props.className}` }
      >
          {conversations.map((conversation,index) =>{
            if( conversation.speaker === "idol"){
              return(<TalkIdol key={index} className="py-3 pr-2" content={conversation.content} icon={props.idol}/>);
            }
            else if( conversation.speaker === "otaku"){
              return(<TalkUser key={index} className="py-3 pl-2" content={conversation.content} icon={props.otaku}/>);
            }
            else{
              return(<TalkStaff key={index} className="py-5" content={conversation.content}/>);
            }
          })}
      </div>
    );

  }
}

export default Conversation;
