import React from 'react';
import { Media } from 'react-bootstrap'

import Balloon from '../../atoms/Balloon';
import IconS from '../../atoms/IconS';

function TalkIdol(props) {
  return (
    <Media className={`${props.className}`}>
      <IconS className="ml-1" icon={props.icon}/>
      <Balloon className="balloonLeft" content={props.content}/>
    </Media>
  );
}

export default TalkIdol;
