import React from 'react';

function Date(props){
  //yyyymmddHHMMを分割
  var time = props.time;
  var result = String(time);
  var year = result.slice(0,4);
  var month = result.slice(4,6);
  var day = result.slice(6,8);
  var hour = result.slice(8,10);
  var minute= result.slice(10,12);

  if(props.withTime){
    return(<span>{year + "/" + month + "/" + day + " " + hour + ":" + minute}</span>);
  }else{
    return(<span>{year + "/" + month + "/" + day}</span>);
  }

}

export default Date;
