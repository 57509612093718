import app from 'firebase/app';
import 'firebase/auth';

const configDev = {
  apiKey: "AIzaSyDQGEXFNL83O-WskMUPWaUMoc433GHsas0",
  authDomain: "otagram-cb091.firebaseapp.com",
  databaseURL: "https://otagram-cb091.firebaseio.com",
  projectId: "otagram-cb091",
  storageBucket: "otagram-cb091.appspot.com",
  messagingSenderId: "603458166229",
  appId: "1:603458166229:web:d3f5169ebb3e0750f4b15c",
  measurementId: "G-5E2JRVLNGT"
};

const config = {
  apiKey: "AIzaSyCQyoSy5r9q-vfhBOrAA_T7Kn89rmHJlxo",
  authDomain: "otagram.firebaseapp.com",
  databaseURL: "https://otagram.firebaseio.com",
  projectId: "otagram",
  storageBucket: "otagram.appspot.com",
  messagingSenderId: "815278485667",
  appId: "1:815278485667:web:29f28b1c5dac3bdb138f16",
  measurementId: "G-WWKJR5LBN2"
};

class Firebase {
  constructor(){
    app.initializeApp(config);

    this.auth = app.auth();
    this.twitterProvider = new app.auth.TwitterAuthProvider();

  }

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();
}

export default Firebase;
