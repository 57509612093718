import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

import './index.css';

function ImageThumbnail(props) {
  if(props.cheki === ""){
    return (
      <div className="adjust-box">
        <div className="inner">
          <LazyLoad height={'100%'} resize>
            <img
              src="../idol_akusyu.png"
              alt="thumbnail"
            />

            <div className="wrapper">
              <Image src={props.icon} roundedCircle />
            </div>
          </LazyLoad>
        </div>
      </div>
    );
  }else{
    return (
      <div className="adjust-box">
        <div className="inner" >
          <LazyLoad height={'100%'}>
            <img
              as={Link}
              to={`/user/${props.id}`}
              src={props.cheki}
              alt="thumbnail"
            />
          </LazyLoad>
        </div>
      </div>
    );
  }



}
export default ImageThumbnail;
