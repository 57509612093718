import React from 'react';


function NewLine(props){
  var caption = props.text;

  return caption
    .split("\n")
    .reduce((accum, x) => [...accum, x, <br />], [])
    .slice(0, -1)
}

export default NewLine;
