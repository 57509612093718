import React from 'react';


function IconM(props) {

  if(props.icon === ""){
    return (
      <img
        width={50}
        height={50}
        className={`${props.className} rounded-circle`}
        src="../person.png"
        alt="Icon"
        style={{backgroundColor:"white"}}
      />
    );
  }else{
    return (
      <img
        width={50}
        height={50}
        className={`${props.className} rounded-circle`}
        src={props.icon}
        alt="Icon"
      />
    );
  }
}


export default IconM;
