import React from 'react';


function ImageCheki(props) {
  if(props.cheki === ""){
    return (
      <div className={`${props.className} text-center`} style={{height: "400px"}} >
        <img
          src="../idol_akusyu.png"
          alt="cheki"
          style={{height: "100%",width:"99%",objectFit:"contain"}}
        />
      </div>
    );
  }else{
    return (
      <div className={`${props.className} text-center`} style={{height: "400px"}}>
        <img
          src={props.cheki}
          alt="cheki"
          style={{height: "100%",width:"99%",objectFit:"contain"}}
        />
      </div>
    );
  }

}
export default ImageCheki;
