import React from 'react';
import { Container} from 'react-bootstrap'

function DownloadReminder(props) {
  return (
    <Container className={`justify-content-center text-center ${props.className}`}  fluid>
      <p className="mb-2">アプリをダウンロード</p>
      <a href="https://t.co/iX7vrxZfrA?amp=1">
        <img className="px-1 py-1" height="50"
        src="../../download-on-the-app-store-apple.svg" alt="Apple Download Banner"/>
      </a>
      {/*<img className="px-1 py-1" height="50"
        src="../../Google_Play_Store_badge_EN.svg" alt="Google Download Banner"/>*/}
    </Container>
  );
}

export default DownloadReminder;
