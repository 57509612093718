import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const Help = () => (
  <div className="help">
    <h3>よくある質問</h3>
    <hr className="my-3"/>


    <strong>レポートを投稿するにはどうしたらいいですか。</strong>
    <p>レポートはおたぐらむアプリから投稿できます。<Link to={ROUTES.TOP}>こちら</Link>からアプリをダウンロードしてください。</p>

    <strong>投稿したレポートを編集することはできますか。</strong>
    <p>いったん投稿したレポートは編集できません。ただしアプリから削除は可能です。</p>

    <strong>レポートは誰に読まれますか。</strong>
    <p>レポートはユーザー全体に共有されます。アプリの「タイムライン」からほかのユーザーの投稿を読むことができます。</p>


    <strong>自分のおたぐらむアカウントを削除するにはどうしたらいいですか。</strong>
    <p>おたぐらむアカウントはアプリから削除可能です。</p>


  </div>
);

export default Help;
